$(document).ready(function() {
    var slids = $('.slider__items');
    var slidsPrev = $('.slider__preveiws');
    var stopPr = false;
    $('.slider__next').on('click',function (e) {
        if (!stopPr){
            stopPr = true;

            var active = $(slids).find('.active');
            var activePr = $(slidsPrev).find('.active');

            var nextEl = active.next();
            if (!nextEl.length) {
                nextEl = $(slids).children().first();
            }
            var nextElPr = activePr.next();
            if (!nextElPr.length) {
                nextElPr = $(slidsPrev).children().first();
            }

            chean(active, activePr, nextEl, nextElPr);

            setTimeout(function () {
                stopPr = false
            },2000)
        }
    });
    $('.slider__prev').on('click',function (e) {
        if (!stopPr){
            stopPr = true;
            var active = $(slids).find('.active');
            var activePr = $(slidsPrev).find('.active');

            var nextEl = active.prev();
            if (!nextEl.length) {
                nextEl = $(slids).children().last();
            }
            var nextElPr = activePr.prev();
            if (!nextElPr.length) {
                nextEl = $(slidsPrev).children().last();
            }

            chean(active, activePr, nextEl, nextElPr);

            setTimeout(function () {
                stopPr = false
            },2000)
        }
    });
    function chean(activeEl, activePr, next, nextElPr) {
        activeEl.removeClass('active');
        activePr.removeClass('active');
        next.addClass('active');
        nextElPr.addClass('active');
    }
});

function headerInit() {
    function headerFix() {
        var headeHeigh = $('#header').outerHeight(),
            offsetTop = $(window).scrollTop();
        if(headeHeigh<offsetTop){
            $('#headerFix').addClass('visiabel')
        } else {
            $('#headerFix').removeClass('visiabel')
        }

    }
    headerFix();
    $(window).scroll(headerFix);
    $(window).resize(headerFix);
}
function swiperInit() {
    $('.swiper-gallary').each(function (el) {
        var mySwiper = new Swiper($(this).children('.swiper-container'), {
            speed: 400,
            spaceBetween: 40,
            slidesPerView: 3,
            nextButton: $(this).children('.swiper-gallary-btn.next'),
            prevButton: $(this).children('.swiper-gallary-btn.prev'),
            buttonDisabledClass: 'disabled',
            breakpoints: {
                // when window width is <= 575px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                // when window width is <= 575px
                575: {
                    slidesPerView: 2,
                    spaceBetween: 30
                }
            }
        });
    });
}
function menuInit() {
    var mobMenu = $('#ml-menu');
    $('.js-toggle_menu').on('click', toggleMenu);
    function toggleMenu() {
        var delayDef = 0.7;
        mobMenu.toggleClass('active');
        mobMenu.find('.menu_mobi__link').each(function (i, item) {
            $(item).toggleClass('active').css({
                'transition-delay' : delayDef + i/10 + 's'
            });
        });
    }
}
function fontInit() {
    var fontBtn = $('.js-cheng-size');
    if (!$.cookie('font_size')){
        $.cookie('font_size', '16px');
    } else {
        var fs = $.cookie('font_size');
        $('html').css({
            'font-size' : fs
        });
        fontBtn.removeClass('active');
        $('[data-size=' + fs + ']').addClass('active')

    }

    function textSizeCheng(size) {
        $.cookie('font_size', size);
        $('html').css({
            'font-size' : size
        })
    }
    fontBtn.on('click', function (e) {
        fontBtn.removeClass('active');
        $(this).addClass('active');
        textSizeCheng($(this).data('size'));
    })
}